@font-face {
  font-family: 'dyno-bold';
  src: url('./dyno-bold.ttf');
}
@font-face {
  font-family: 'dyno-bolditalic';
  src: url('./dyno-bolditalic.ttf');
}
@font-face {
  font-family: 'dyno-italic';
  src: url('./dyno-italic.ttf');
}
@font-face {
  font-family: 'dyno';
  src: url('./dyno-regular.ttf');
}
