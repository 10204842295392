:root {
  --light-blue: #208ab9;
  --border-blue: #34658c;
  --yellow: #ffea02;
}

.app-header {
  text-align: center;
  padding: 1em;
}

.header-title {
  color: #ffea02;
  font-family: countach-bolditalic;
  font-size: 2.7em;
  text-align: center;
  text-shadow: 1px 1px 2px #000;
  text-transform: uppercase;
}

.header-title > span.subtitle {
  font-size: 0.8em;
  font-weight: 400;
}

.list-title {
  color: white;
  font-family: countach-italic;
  text-shadow: 1px 1px 2px #000;
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
}

.borderlands-card {
  position: relative;
  background-color: #082136 !important;
  border: 2px solid var(--light-blue) !important;
  border-radius: 0 !important;
  box-shadow: -5px -5px 0 0 #01447783;
  font-family: countach-bolditalic;
  padding: 2px !important;
  margin-bottom: 1em;
  transition: all 0.1s ease-in-out;
}

.borderlands-card:hover {
  box-shadow: -10px -10px 0 0 #01447783;
  cursor: pointer;
  transform: translate(5px, 5px);
}

.new-shift-code:after {
  position: absolute;
  right: -10px;
  top: calc(50% - 10px);
  content: "!";
  border-radius: 50%;
  background: #42350b;
  width: 20px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  color: yellow;
  font-weight: bolder;
  border: 2px solid yellow;
  box-shadow: 0 0 0 2px #42350b, 0 0 5px 5px yellow;
  font-size: 16px;
}

/* .borderlands-card:before {
  content:"";
  position:absolute;
  top:0;
  left:0;
  border-width: 15px 15px 0px 0px;
  border-style: solid;
  border-color: var(--light-blue) transparent transparent transparent ;
} */

.borderlands-card-container {
  background: linear-gradient(to bottom, #005395 0%, #014377 100%);
  padding: 1em;
}

.shift-code {
  color: white !important;
  font-size: 1.6em;
  text-shadow: 1px 1px 2px #000;
  -webkit-text-stroke: 1px #000;
}

.borderlands-card-description {
  color: #2f7c9e;
  font-size: 1.2em;
  margin-top: 0.3em;
  text-shadow: 1px 1px 2px #000;
}

.borderlands-card > .content {
  padding: 3px !important;
}

.borderlands-card > .content > .header {
  color: white !important;
  text-shadow: 1px 1px 2px #000;
  background: linear-gradient(to bottom, #005395 0%, #014377 100%);
}

.borderlands-card > .content > .meta {
  color: #5d8094 !important;
  text-shadow: 1px 1px 2px #000;
}

.borderlands-card-tags-container {
  margin-top: 0.3em;
}

.borderlands-card-tag {
  padding: 0px 5px;
  border-radius: 2px;
  background-color: gray;
  color: white;
  display: inline-block;
  font-size: 0.75em;
  font-family: "dyno-bold";
  margin-right: 0.3em;
  box-shadow: -5px -5px 0 0 #01447783;
}

.borderlands-card-tag.borderlands2 {
  background-color: rgb(190, 133, 27);
}

.borderlands-card-tag.borderlands3 {
  background-color: var(--light-blue);
}

.footer {
  padding: 1em;
  text-align: center;
  color: white;
  font-family: countach-italic;
  text-shadow: 1px 1px 2px #000;
}

.bmac-link {
  text-decoration: none;
}

.bmac-link:link,
.bmac-link:visited {
  color: white;
}

.bmac-link:hover {
  color: var(--yellow);
}
