/*
	Copyright (C) Production Type
	This software is the property of Production Type.
	Your right to access and use this software is subject to the
	applicable License Agreement, or Terms of Service, that exists
	between you and Production Type. If no such agreement exists,
	you may not access or use this software for any purpose.
	This software may only be hosted at the locations specified in
	the applicable License Agreement or Terms of Service, and only
	for the purposes expressly set forth therein. You may not copy,
	modify, convert, create derivative works from or distribute this
	software in any way, or make it accessible to any third party,
	without first obtaining the written permission of Production Type.
	For more information, please visit us at www.productiontype.com
*/
@font-face {
  font-family: 'countach-bold';
  src: url('./countachweb-bold-TRIAL.eot'),
    url('./countachweb-bold-TRIAL.eot?#iefix') format('embedded-opentype'),
    url('./countachweb-bold-TRIAL.woff') format('woff'),
    url('./Countach-Bold-TRIAL.otf') format('opentype');
}
@font-face {
  font-family: 'countach-bolditalic';
  src: url('./countachweb-bolditalic-TRIAL.eot'),
    url('./countachweb-bolditalic-TRIAL.eot?#iefix') format('embedded-opentype'),
    url('./countachweb-bolditalic-TRIAL.woff') format('woff'),
    url('./Countach-BoldItalic-TRIAL.otf') format('opentype');
}
@font-face {
  font-family: 'countach-italic';
  src: url('./countachweb-italic-TRIAL.eot'),
    url('./countachweb-italic-TRIAL.eot?#iefix') format('embedded-opentype'),
    url('./countachweb-italic-TRIAL.woff') format('woff'),
    url('./Countach-Italic-TRIAL.otf') format('opentype');
}
@font-face {
  font-family: 'countach-light';
  src: url('./countachweb-light-TRIAL.eot'),
    url('./countachweb-light-TRIAL.eot?#iefix') format('embedded-opentype'),
    url('./countachweb-light-TRIAL.woff') format('woff'),
    url('./Countach-Light-TRIAL.otf') format('opentype');
}
@font-face {
  font-family: 'countach-lightitalic';
  src: url('./countachweb-lightitalic-TRIAL.eot'),
    url('./countachweb-lightitalic-TRIAL.eot?#iefix') format('embedded-opentype'),
    url('./countachweb-lightitalic-TRIAL.woff') format('woff'),
    url('./Countach-LightItalic-TRIAL.otf') format('opentype');
}
@font-face {
  font-family: 'countach-regular';
  src: url('./countachweb-regular-TRIAL.eot'),
    url('./countachweb-regular-TRIAL.eot?#iefix') format('embedded-opentype'),
    url('./countachweb-regular-TRIAL.woff') format('woff'),
    url('./Countach-Regular-TRIAL.otf') format('opentype');
}